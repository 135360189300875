
          @import '@/assets/scss/_functions.scss';
          @import '@/assets/scss/_variables.scss';
          @import '@/assets/scss/_mixins.scss';
        

//
.table-search-input {
  width: 246px;
  .search-icon {
    color: $gk-grey-2;
  }
}
